@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'DIN';
  font-style: normal;
  font-weight: normal;
  src: url('./images/DINAlternate-Bold.woff') format('woff');
}

html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  min-width: 1200px;
}


#root {
  max-width: 1200px;
  margin: 0 auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}